import {inject, Inject, Injectable, InjectionToken, PLATFORM_ID} from '@angular/core';
import {SsrCookieService} from "ngx-cookie-service-ssr";
import {isPlatformServer} from "@angular/common";

export enum StorageDataKey {
  socialUser = 'socialUser',
  apiUser = 'apiUser',
  jwtToken = 'jwtToken',
  feedLayout = 'feedLayout',
  onboarding = 'onboarding',
  referrerCode = 'referrerCode',
  browserId = 'browserId',
  fcmToken = 'fcmToken',
  postTagsFilter = 'postTagsFilter',
  desirenFilter = 'desirenFilter',
  language = 'language',
}

export enum StorageType {
  cookie,
  local,
  session
}

const MockStorage = {
  getItem(key: string): string | null {
    return null;
  },
  setItem(key: string, value: string) {
  },
  clear() {
  },
  key(index: number): string | null {
    return null;
  },
  removeItem(key: string) {
  },
};

export const BROWSER_STORAGE = new InjectionToken<Storage | {}>('BROWSER_STORAGE', {
  providedIn: 'root',
  factory: () => {
    const platformId = inject(PLATFORM_ID);
    if (isPlatformServer(platformId)) {
      return MockStorage;
    }
    return localStorage;
}});

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  constructor(
    @Inject(BROWSER_STORAGE) public storage: Storage,
    private cookies: SsrCookieService,
  ) {
  }

  getLocalData(key: StorageDataKey): string | null {
    return this.storage.getItem(key);
  }
  setLocalData(key: StorageDataKey, value: string | null): void {
    return this.storage.setItem(key, value);
  }
  clearLocalData(key: StorageDataKey): void {
    return this.storage.removeItem(key);
  }
  getLocalJsonData<T = any>(key: StorageDataKey): T | null {
    try {
      const memoryData = this.storage.getItem(key);
      if (memoryData != undefined && memoryData!.length > 0) {
        return JSON.parse(memoryData) as T;
      }
      return null;
    } catch (e) {
      console.error(e);
      return null;
    }
  }
  setLocalJsonData(key: StorageDataKey, data: any): void {
    const string = JSON.stringify(data);
    this.storage.setItem(key, string);
  }

  getAuthToken(): string | null {
    return this.getLocalData(StorageDataKey.jwtToken);
  }
  clearLocalStorage(): void {
    const keysToDelete = Object.values(StorageDataKey);
    keysToDelete.forEach(key => this.storage.removeItem(key));
  }
}
